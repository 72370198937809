import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ComplaintProcedureTemplate = () => (
  <Layout>
    <Seo title="Procedura reklamacji" />
    <section className="section container page-single">
      <h2 className="section-title">Procedura reklamacji</h2>
      <ol className="complaint-procedure">
        <li className="complaint-procedure-step">
          <div className="complaint-procedure-step-number">1</div>
          <div className="complaint-procedure-step-description">
            Uczestnik ma prawo do złożenia reklamacji w terminie 14 dni kalendarzowych od daty zakończenia świadczenia usługi szkoleniowej.
          </div>
        </li>
        <li className="complaint-procedure-step">
          <div className="complaint-procedure-step-number">2</div>
          <div className="complaint-procedure-step-description">
            Reklamacja musi zostać złożona w formie pisemnej listem poleconym, faksem lub osobiście na adres Dworska 51, 34-100 Tomice, Polska. Za datę złożenia reklamacji o której mowa w pkt 1 powyżej uznaje się datę stempla pocztowego lub datę wpływu faksu lub złożenia osobiście.
          </div>
        </li>
        <li className="complaint-procedure-step">
          <div className="complaint-procedure-step-number">3</div>
          <div className="complaint-procedure-step-description">
            Zgłoszenie reklamacyjne powinno zawierać:
            <ul>
              <li>- nazwę/imię i nazwisko uczestnika oraz adres siedziby/miejsce zamieszkania uczestnika, </li>
              <li>- przedmiot reklamacji (nazwa szkolenia, termin, miejsce, itp.) wraz z uzasadnienieniem.</li>
            </ul>
          </div>
        </li>
      </ol>
    </section>
  </Layout>
);

export default ComplaintProcedureTemplate;
